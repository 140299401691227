<template>
  <div>
    <Menu :menu="accessMenu" prefix="" />
    <transition name="page">
      <router-view />
    </transition>
  </div>
</template>

<script>
import Menu from '@/components/atoms/Menu.vue'
import { ADDWINE_ADMIN_ROUTES } from '@/constants/routing'
import { mapGetters } from 'vuex'

export default {
  components: {
    Menu,
  },
  data() {
    return {
      menu: [
        {
          title: 'Главная',
          link: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.MAIN.INDEX,
        },
        {
          title: 'Контакты',
          link: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.CONTACTS.INDEX,
        },
        {
          title: 'Статьи',
          link: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.ARTICLES.INDEX,
        },
        {
          title: 'Каталог',
          link: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.CATALOGUE.INDEX,
        },
        {
          title: 'О компании',
          link: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.ABOUT.INDEX,
        },
        {
          title: 'Политика Конф.',
          link: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.PRIVATE_POLICY.INDEX,
        },
        {
          title: 'Общее',
          link: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.COMMON.INDEX,
        },
        {
          title: 'Акции',
          link: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.PROMOTIONS.INDEX,
        },
        {
          title: 'Доставка и оплата',
          link: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.DELIVERY.INDEX,
        },
        {
          title: 'Гарантия',
          link: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.WARRANTY.INDEX,
        },
        {
          title: 'Отмена покупки',
          link: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.PURCHASE_CANCELLATION.INDEX,
        },
        {
          title: 'Бонусная система',
          link: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.BONUS_INFO.INDEX,
        },
        {
          title: 'Возврат товара',
          link: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.PURCHASE_RETURNS.INDEX,
        },
        {
          title: 'Стать поставщиком',
          link: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.BECOME_A_SUPPLIER.INDEX,
        },
        {
          title: 'Документы',
          link: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.DOCUMENTS.INDEX,
        },
        {
          title: 'Как сделать заказ',
          link: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.ORDER_INFO.INDEX,
        },
        {
          title: 'Клиенты',
          link: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.CLIENTS.INDEX,
        },
        {
          title: 'Партнеры',
          link: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.PARTNERS.INDEX,
        },
        {
          title: 'Вакансии',
          link: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.VACANCIES.INDEX,
        },
        {
          title: 'Команда проекта',
          link: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.TEAM.INDEX,
        },
        {
          title: 'Отзывы',
          link: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.REVIEWS.INDEX,
        },
        {
          title: 'Политика ПД',
          link: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.PROCESSING_POLICY.INDEX,
        },
        {
          title: 'Публичная оферта',
          link: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.PUBLIC_OFFER.INDEX,
        },
        {
          title: 'Политика Cookies',
          link: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.COOKIES_POLICY.INDEX,
        },
        {
          title: 'Политика сертификатов',
          link: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.CERTIFICATES_POLICY.INDEX,
        },
        { title: 'FAQ', link: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.FAQ.INDEX },
      ],
    }
  },
  computed: {
    ...mapGetters({
      filterAccessMenuList: 'filterAccessMenuList',
    }),
    accessMenu() {
      return this.filterAccessMenuList(this.menu)
    },
  },
}
</script>
